<template>
  <v-col cols="12" sm="10" md="8" lg="6" xl="6">
    <v-card flat>
      <v-card-title>
        <v-row align="center" justify="center">
          <h3> {{ $t('actions.resetPassword') }} </h3>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <v-row align="center" justify="center">
          <h4> {{ $t('views.constants.resetPassword.enterEmail') }} </h4>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <app-form :fields="fields" :submit-button="submitButton" disable-cancel-button @submitted="handleSubmit" />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ResetPassword',
  data() {
    return {
      fields: {
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          rules: [{
            name: 'required'
          }, {
            name: 'email'
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        }
      },
      submitButton: {
        label: this.$t('actions.resetPassword'),
        block: true
      }
    };
  },
  methods: {
    ...mapActions(['resetPassword']),
    handleSubmit(data) {
      this.resetPassword(data).then((response) => {
        if (!this.successCode(response.status)) {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    }
  }
};
</script>
